import React from "react";
import Imgspeak from "./../images/dealersys long black.png";
import { MdOutlineEmail, MdOutlineChatBubbleOutline } from "react-icons/md";
import { Link } from "gatsby";
import digitalLeader from "../images/dealerlogo.png";
function Speak({ location }) {
  return (
    <div className="bg-black">
      <div className="w-full max-w-6xl px-8 py-16 mx-auto">
        <div className="flex flex-wrap justify-between">
          <div className="mb-10 md:mb-0">
            <img
              src={
                (location !== undefined &&
                  location.pathname !== undefined &&
                  location.pathname === "/digital-dealer") ||
                location.pathname === "/contest-rules"
                  ? digitalLeader
                  : Imgspeak
              }
              alt=""
              className="w-60"
            />
            <h1 className="mt-4 text-2xl text-white md:text-4xl ">
              Speak with an expert.
            </h1>
          </div>
          <div className="flex self-end">
            <div>
              <div className="mb-4">
                <p className="mb-2 text-lg text-white">
                  Toll Free Number: 888-483-4020
                </p>
              </div>
              <div className="flex">
                <a
                  href="mailto:sales@dealersys.com"
                  className="flex mr-8 text-white"
                >
                  <MdOutlineEmail className="mr-1 text-2xl" />
                  <span className="uppercase border-b-2 border-white ">
                    Email
                  </span>
                </a>
                <Link to="/contact-us">
                  <a className="flex text-white">
                    <MdOutlineChatBubbleOutline className="mr-2 text-2xl " />
                    <span className="uppercase border-b-2 border-white ">
                      Chat
                    </span>
                  </a>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Speak;

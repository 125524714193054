import React from "react";
import Headerp from "./../../images/Partner_Contract.svg";

function Header() {
  return (
    <div className="header-gradient ">
      <div className="px-8 py-10 m-auto max-w-7xl md:py-16">
        <div className="grid grid-cols-1 md:grid-cols-2">
          <div>
            <div className="mb-2">
              <span className="text-xs font-medium text-white"> PARTNERS</span>
            </div>
            <h1 className="mb-4 text-5xl font-medium text-white ">
              Become a Partner
            </h1>
            <p className="mb-16 text-xl font-normal text-white">
              Unified communications. Impressive integrations. Innovative APIs.
              With Vonage, you can take advantage of our full spectrum of cloud
              communications capabilities—and grab a bigger piece of the pie.
              Expand your revenue potential by joining the Vonage Partner
              Network. We offer a multitude partner relationship options related
              to each of our product categories. Explore these below.
            </p>
          </div>
          <div className="justify-self-center">
            <img src={Headerp} alt="" className="w-56" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;

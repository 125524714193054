import React from "react";
import Findapatner from "./Findapatner";
import Header from "./Header";
import Speak from "./../../components/Speak";
import Cards2 from "./Cards2";
import data from "./../../data";

function index({location}) {
  return (
    <div>
      <Header />
      <Cards2 patners={data.patners} />
      <Findapatner />
      <Speak  location={location}/>
    </div>
  );
}

export default index;

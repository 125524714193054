import React from "react";
import Find from "./../../images/Laptop_VideoHello.svg";

function Findapatner() {
  return (
    <div>
      <div className="max-w-7xl m-auto md:pt-10 pt-6 md:pb-20 pb-10 px-8 ">
        <div className="flex justify-center">
          <a href="/" className="px-7 text-white py-6 bg-black rounded-lg ">
            find a patner
          </a>
        </div>
        <div className="max-w-5xl m-auto mt-12">
          <div className="bg-black relative text-white grid md:grid-cols-3 grid-cols-1 lg:gap-10 gap-5 md:px-12 px-8 py-20">
            <div>
              <img
                src={Find}
                alt=""
                className="lg:w-2/5 w-full lg:absolute md:-left-16"
              />
            </div>
            <div className="col-span-2">
              <h1 className="md:text-3xl text-2xl font-black mb-5">
                Drive holistic partner experiences across contact centers and
                unified communications
              </h1>
              <p className="md:text-lg text-base font-normal mb-16">
                Learn about the intuitive tools and solutions Vonage has to
                offer to ensure our success is your success.
              </p>
              <a
                href="/"
                className="font-semibold tracking-wide border-b-2 border-white"
              >
                LEARN MORE ABOUT THE VONAGE PARTNER EXPERIENCE
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Findapatner;

import React from "react";

const Cards = ({ patners }) => {
  return (
    <div className=" ">
      <div className="max-w-7xl m-auto md:pt-10 pt-6 md:pb-20 pb-10 px-8 ">
        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 text-center justify-items-center  md:mt-16 mt-8 gap-10">
          {patners.map((item, index) => {
            return (
              <div >
                <Card2
                  index={index}
                  title={item.title}
                  link={item.link}
                  img={item.img}
                  desc={item.desc}
                  src={item.src}
                  btn={item.btn}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Cards;

const Card2 = ({ title, link, desc, btn }) => {
  return (
    <div >
      <div >
        <h1 className="text-lg font-semibold mb-2">{title}</h1>
        <p className="text-base font-light mb-3">{desc}</p>

        <a href={link}>
          <span className="text-sm font-semibold border-b-2 border-black ">
            {btn}
          </span>
        </a>
      </div>
    </div>
  );
};
